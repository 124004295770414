import logo from './logo.svg';
import './App.css';
import FileUploader from './component/File-Uploader';
import FruitDetails from './component/Fruite-Details';
import './bootstrap.css'
import './common.css'
import HomePage from './component/home';
import { React, useState } from 'react';
import { render } from 'react-dom';


function App() {
  const [sharedValue, setSharedValue] = useState('');

  const handleValueChange = (newValue) => {
    setSharedValue(newValue);
  };

  return (
    <div className="App">

      {sharedValue === '' ? (
        <HomePage onValueChange={handleValueChange} />) : (<FileUploader passedValue={sharedValue} />)}
        
    </div>
  );
}

export default App;
