import Watermelon from './../images/Rectangle_A.png';
import banana from './../images/Rectangle_B.png';
import ginger from './../images/Rectangle_L.png';
import mango from './../images/Rectangle_Ma.png';
import mausami from './../images/Rectangle_BG.png';
import papaya from './../images/Rectangle_Pa.png';
import arrow from'./../images/Arrow.png';
import './home.css';
import { React, useRef, useState } from 'react';
const HomePage = ({ onValueChange }) => {
    const [value, setValue] = useState('');
    const handleChange = (event) => {
        const newValue = event.target.alt;
        onValueChange(newValue);
    };
    const handleChange1 = () => {
        window.location.href = "https://audo.shabari.ai/"
    };
    return (
        <div className="container-fluid bg-image-home">
            <div className="text-center logo">
                    <p className="color-green">SHABARI.<span className="color-white">AI</span></p>
                </div>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-3 container">
                                <h3 className="font-weight-bold">Fruit</h3>
                               <p> <img src={arrow} className='pt-4 pr-2'/>Select fruit to scan purity</p>
                            </div>
                            <div className="container col-sm-12">
                                <div className="container col-sm-10 pb-3">
                                    <div className="row justify-content-md-center">
                                        <div className='row col-sm-6'>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={papaya} alt="Papaya" className='img-home-page' />
                                                <p className="font-weight-bold font-size-home-fruit" >Papaya</p>
                                            </div>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={banana} className='img-home-page' alt="Banana"  />
                                                <p className="font-weight-bold font-size-home-fruit">Banana</p>
                                            </div>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={mango} className='img-home-page' alt="Mango"  />
                                                <p className="font-weight-bold font-size-home-fruit">Safeda Mango</p>
                                            </div>
                                        </div>
                                        <div className='row col-sm-6'>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={ginger}  className='img-home-page' alt="lemon"  />
                                                <p className="font-weight-bold font-size-home-fruit">Lemon</p>
                                            </div>
                                            <div className='col-4' >
                                                <img src={Watermelon} onClick={handleChange1} className='img-home-page' alt="Watermelon"  />
                                                <p className="font-weight-bold font-size-home-fruit">Watermelon</p>
                                            </div>
                                            <div className='col-4'>
                                                <img src={mausami} onClick={handleChange} className='img-home-page' alt="Bael"  />
                                                <p className="font-weight-bold font-size-home-fruit">Bael</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HomePage;