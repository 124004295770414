import pointer from './../images/Group 74.png';
import './Fruite-Details.css'
import { React, useRef, useEffect, useState } from 'react';
const FruitDetails = (props) => {
    const [indictorePadding, setPadding] = useState(0);
    const indictoreValue = Number(props.fruitDetails.Sweetness);
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            console.log('width', ref.current.offsetWidth);
            setPadding(`${(ref.current.offsetWidth * indictoreValue) - ref.current.offsetWidth / 2 - 5}px`);
        }
    }, [indictoreValue]);
    if (props.fruitDetails.passedValue1 === "Mausami"){
        return(
            <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                <div className="text-center logo">
                    <p className="color-green">SHABARI.<span className="color-white">AI</span></p>
                </div>
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-3 pb-4 container">
                                <h3 className="text-left font-weight-bold ">Know about {props.fruitDetails.passedValue1} - <span className='green-color'>SHABARI</span>.AI</h3>
                            </div>
                            <div className="container col-sm-12">
                                <div className="container col-sm-12 pb-3">
                                    <div className="row text-left">
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">Ripening</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Ripening}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-0">
                                            <div className="result-box pb-0" >
                                            <p className="m-0 pb-1 font-weight-bold color-green">JUICINESS</p>
                                                <p className="m-0 pb-2 txt-indictor font-weight-bold color-green" data-number={indictoreValue}></p>

                                                <div className="row justify-content-md-center">
                                                <div ref={ref} className="result-scale btn-scale-asc-1">1</div>
                                                    <div className="result-scale btn-scale-asc-2">2</div>
                                                    <div className="result-scale btn-scale-asc-3">3</div>
                                                    <div className="result-scale btn-scale-asc-4">4</div>
                                                    <div className="result-scale btn-scale-asc-5">5</div>
                                                    <div className="result-scale btn-scale-asc-6">6</div>
                                                    <div className="result-scale btn-scale-asc-7">7</div>
                                                    <div className="result-scale btn-scale-asc-8">8</div>
                                                    <div className="result-scale btn-scale-asc-9">9</div>
                                                    <div className="result-scale btn-scale-asc-10">10</div>
                                                </div>
                                                <div className='row'>
                                                    <img src={pointer} data-number={indictoreValue} style={{ 'paddingLeft': indictorePadding }} />  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">VARIETY</p>
                                                <p>KAGZI<span className="font-size-small">
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row col-sm-12 pt-3 justify-content-md-center">
                                        <div className="col-sm-7 pt-3 text-left">
                                            <h5 className="font-weight-bold">NUTRIENT INFORMATION: {props.fruitDetails.passedValue1}</h5>
                                            <span className="color-red font-size-small">Every 100 gms of {props.fruitDetails.passedValue1} contain</span>
                                            <ul>
                                            <li>Calories: 43 Kcal</li>
                                            <li>Carbohydrates: 11g</li>
                                            <li>Fiber: 1.7 g</li>
                                            <li>Sugars: 7.8 g</li>
                                            <li>Protein: 0.5 g</li>
                                            <li>Vitamin C: 60.9 mg (101% of the Daily Value)</li>
                                            <li>Vitamin A: 950 IU (19% of the Daily Value) </li>
                                            <li>Folate (B9): 37 μg (9% of the Daily Value)</li>
                                            <li>Potassium: 182 mg (5% of the Daily Value)</li>
                                            <li>Good source of antioxidants, such as carotenoids and flavonoids</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5 pt-3  text-left">
                                            <h5 className="font-weight-bold">DISCLAIMER</h5>
                                            <ul>
                                                <li>Lemon taste better if kept under ideal condition including
                                                     Temperature 20° C, Humidity@ 75%</li>
                                                <li>SHABARI.AI provides insights on the quality and purity of fruits using advanced Al models with a proven
                                                    accuracy rate of 95%. However, for complete assurance, it is recommended tocorroborate the results through
                                                    a
                                                    comprehencive analysis conducted
                                                    at an NABL-certified laboratory</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className='col-sm-3'>
                                            <a href="/" className="btn btn-fruit col-sm-12 text-center font-weight-bold">
                                                Reset</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
    else if(props.fruitDetails.passedValue1 === "Mango"){
        return(
            <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                <div className="text-center logo">
                    <p className="color-green">SHABARI.<span className="color-white">AI</span></p>
                </div>
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-3 pb-4 container">
                                <h3 className="text-left font-weight-bold ">Know about Safeda {props.fruitDetails.passedValue1} - <span className='green-color'>SHABARI</span>.AI</h3>
                            </div>
                            <div className="container col-sm-12">
                                <div className="container col-sm-12 pb-3">
                                    <div className="row text-left">
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">Ripening</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Ripening}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-0">
                                            <div className="result-box pb-0" >
                                            <p className="m-0 pb-1 font-weight-bold color-green">SWEETNESS</p>
                                                <p className="m-0 pb-2 txt-indictor font-weight-bold color-green" data-number={indictoreValue}></p>
                                                <div className="row justify-content-md-center">
                                                    <div ref={ref} className="result-scale btn-scale-asc-1">1</div>
                                                    <div className="result-scale btn-scale-asc-2">2</div>
                                                    <div className="result-scale btn-scale-asc-3">3</div>
                                                    <div className="result-scale btn-scale-asc-4">4</div>
                                                    <div className="result-scale btn-scale-asc-5">5</div>
                                                    <div className="result-scale btn-scale-asc-6">6</div>
                                                    <div className="result-scale btn-scale-asc-7">7</div>
                                                    <div className="result-scale btn-scale-asc-8">8</div>
                                                    <div className="result-scale btn-scale-asc-9">9</div>
                                                    <div className="result-scale btn-scale-asc-10">10</div>
                                                </div>
                                                <div className='row'>
                                                    <img src={pointer} data-number={indictoreValue} style={{ 'paddingLeft': indictorePadding }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">VARIETY</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Maturity} <span className="font-size-small">
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row col-sm-12 pt-3 justify-content-md-center">
                                        <div className="col-sm-7 pt-3 text-left">
                                            <h5 className="font-weight-bold">NUTRIENT INFORMATION: {props.fruitDetails.passedValue1}</h5>
                                            <span className="color-red font-size-small">Every 100 gms of {props.fruitDetails.passedValue1} contain</span>
                                            <ul>
                                            <li>Calories: 60 Kcal</li>
                                            <li>Carbohydrates: 15g</li>
                                            <li>Fiber: 1.6 g</li>
                                            <li>Sugars: 13.7 g</li>
                                            <li>Protein: 0.8 g</li>
                                            <li>Vitamin C: 36.4 mg (61% of the Daily Value)</li>
                                            <li>Vitamin A: 54 IU (1% of the Daily Value)</li>
                                            <li>Potassium: 168 mg (4% of the Daily Value)</li>
                                            <li>Good source of antioxidants, such as vitamin A, vitamin C, and beta-carotene</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5 pt-3  text-left">
                                            <h5 className="font-weight-bold">DISCLAIMER</h5>
                                            <ul>
                                            <li>Mango tastes better if kept under ideal conditions including Temperature 25°C, Humidity@ 85%</li>
                                            <li>SHABARI.AI provides insights on the quality and purity of fruits using advanced AI models with a proven accuracy rate of 95%. However, for complete assurance, it is recommended to corroborate the results through a comprehensive analysis conducted at an NABL-certified laboratory</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className='col-sm-3'>
                                            <a href="/" className="btn btn-fruit col-sm-12 text-center font-weight-bold">
                                                Reset</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
    else{
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + props.fruitDetails.Maturity);
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();

        // let date=currentDate+props.fruitDetails.Maturity;
        // currentDate.setDate(currentDate.getDate() + props.fruitDetails.Maturity);
        let date= day+'-'+month+'-'+year;
    return (
        <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                <div className="text-center logo">
                    <p className="color-green">SHABARI.<span className="color-white">AI</span></p>
                </div>
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-3 pb-4 container">
                                <h3 className="text-left font-weight-bold ">Know about {props.fruitDetails.passedValue1} - <span className='green-color'>SHABARI</span>.AI</h3>
                            </div>
                            <div className="container col-sm-12">
                                <div className="container col-sm-12 pb-3">
                                    <div className="row text-left">
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">Ripening</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Ripening}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-0">
                                            <div className="result-box pb-0" >
                                            <p className="m-0 pb-1 font-weight-bold color-green">SWEETNESS</p>
                                                <p className="m-0 pb-2 txt-indictor font-weight-bold color-green" data-number={indictoreValue}></p>
                                                <div className="row justify-content-md-center">
                                                    <div ref={ref} className="result-scale btn-scale-asc-1">1</div>
                                                    <div className="result-scale btn-scale-asc-2">2</div>
                                                    <div className="result-scale btn-scale-asc-3">3</div>
                                                    <div className="result-scale btn-scale-asc-4">4</div>
                                                    <div className="result-scale btn-scale-asc-5">5</div>
                                                    <div className="result-scale btn-scale-asc-6">6</div>
                                                    <div className="result-scale btn-scale-asc-7">7</div>
                                                    <div className="result-scale btn-scale-asc-8">8</div>
                                                    <div className="result-scale btn-scale-asc-9">9</div>
                                                    <div className="result-scale btn-scale-asc-10">10</div>
                                                </div>
                                                <div className='row'>
                                                    <img src={pointer} data-number={indictoreValue} style={{ 'paddingLeft': indictorePadding }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">Best Taste</p>
                                                {/* <p>{props.fruitDetails.Maturity} <span className="font-size-small">
                                                </span></p> */}
                                                <p>{date} <span className="font-size-small">
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row col-sm-12 pt-3 justify-content-md-center">
                                        <div className="col-sm-7 pt-3 text-left">
                                            <h5 className="font-weight-bold">NUTRIENT INFORMATION: {props.fruitDetails.passedValue1}</h5>
                                            <span className="color-red font-size-small">Every 100 gms of {props.fruitDetails.passedValue1} contain</span>
                                            <ul>
                                                <li>Calories: 43 Kcal</li>
                                                <li>Carbohydrates : 11g</li>
                                                <li>Fiber: 1.7 g</li>
                                                <li>Sugars: 7.8 g</li>
                                                <li>Protein: 0.5 g</li>
                                                <li>Vitamin C: 60.9 mg (101% of the Daily Value)</li>
                                                <li> Vitamin A: 950 IU (19% of the Daily Value)</li>
                                                <li>Folate (B9): 37 µg (9% of the Daily Value)</li>
                                                <li>Potassium: 182 mg (5% of the Daily Value)</li>
                                                <li> Good source of antioxidants, such as carotenoids and flavonoid</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5 pt-3  text-left">
                                            <h5 className="font-weight-bold">DISCLAIMER</h5>
                                            <ul>
                                                <li>{props.fruitDetails.passedValue1} Tastes better if kept under ideal condition including Temperature 20 degreed Celsius,
                                                    Humidity@ 75%</li>
                                                <li>SHABARI.Al provides insights on the quality and purity of fruits using advanced Al models with
                                                    a proven accuracy rate of 95%.However, for complete assurance, it is recommended tocorroborate
                                                    the results through a comprehencive analysis conducted at an NABL-certified laboratory</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className='col-sm-3'>
                                            <a href="/" className="btn btn-fruit col-sm-12 text-center font-weight-bold">
                                                Reset</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );}}
export default FruitDetails;
